.upcoming-page {
    text-align: center;
    background-color: rgba(244,194,194, 0.85);
    height: 120%;
}

.upcoming-header {
    font-size: 16vh;
    font-family: 'Oleo Script';
    margin-top: 5vh;
    color: #ffefd5;
}

.upcoming-head {
    font-family: 'Georgia';
    font-size: 8vh;
}

.upcoming-works-list {
    list-style: none;
}

.upcoming-li {
    font-size: 5vh;
    font-style: italic; 
    font-family: 'Georgia';
}

.upcoming-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 5vh;
    padding: 5vw;
    background-color: rgb(181,114,129, 0.8);
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
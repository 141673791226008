.submenu {
    display: flex;
    justify-content: center;
}

.gallery-category-button {
    background-color: none;
    color: white;
    border: 0;
    border-radius: 0px;
    font-size: 1.5vw;
}
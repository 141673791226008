.about-page {
    text-align: center;
    background-color: rgba(97,64,81,0.85);
    height: 120%;
}

.navbar {
    align-self: center;
}

.name-header {
    font-size: 16vh;
    font-family: 'Oleo Script';
    margin-top: 5vh;
    color: #ffefd5;
}

.headshot-image {
    height: 60vh;
    width: 25vw;
    margin-bottom: 5vh;
    align-self: center;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.about-container {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 5vh;
    padding: 5vw;
    background-color: rgb(179,158,181, 0.85);
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.about-text {
    width: 40vw;
    margin-left: 5vw;
    align-self: center;
    font-family: 'Georgia';
    font-size: 1.25vw;
}
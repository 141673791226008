.gallery-image {
    display: block;
    max-width: 50%;
    max-height: 35%;
    width: auto;
    height: auto;
    align-self: center;
    margin: .5vw;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.gallery {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

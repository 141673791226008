.link-list {
    list-style: none;
}

.materMediaIcon {
    height: 7vh;
    width: 12vw;
    margin: 1vh;
}

.amazonIcon {
    height: 7vh;
    width: 13vw;
}

.purchase-links {
    padding: 5vh;
}

.purchase-text {
    font-weight: bold;
    font-family: 'Georgia';
    font-size: 4vh;
}
.Form {
    text-align: center;
    max-width: 30vw;
    margin: 20px auto;
    font-family: 'Georgia';
  }
  
Form {
    border-radius: 8px;
    padding: 20px;
    background-color: rgb(0,128,128, 0.8);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 2vh;
  }

.form-title {
    font-size: 3vh;
    font-weight: bold;
    font-family: 'Georgia';
}
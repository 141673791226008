.castlecottage {
  text-align: center;
  background-color: rgba(188,184,138, 0.4);
  height: 100%;
}

.cc-background-image {
  opacity: 0.4;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 170vh;
  margin-top: 10vh;
  z-index: -10;
}

.cover-image {
  height: 55vh;
  width: 30vw;
  margin-left: 2vw;
  align-self: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.video-container {
    width: 80%;
    height: 100%;
    margin-left: 29vw;
    margin-top: 2%;
  }

  
  /* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
    width: 100%;
    height: 100%;
  }

.Video-Player {
    height: 45vh;
    width: 55vw;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-top: 5%;
}

.castlecottage-heading {
  margin-top: 5vh;
  align-self: center;
  font-size: 20vh;
  font-family: 'Uncial Antiqua';
}

.blurb-container {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-bottom: 5vh;
  padding: 2vw;
  background-color: rgb(138, 154, 91, 0.8);
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.blurb-text {
  align-self: center;
  font-family: 'Georgia';
  font-size: 1.20vw;
}

.blurb-title {
  margin-bottom: 5vh;
  align-self: center;
  font-family: 'Georgia';
  font-size: 2.5vw;
  font-weight: bold;
}

.text-contain {
  margin-left: 5vw;
  margin-right: 5vw;
}

.review-container {
  margin-left: 10vw;
  margin-right: 10vw;
  margin-bottom: 5vh;
  padding: 2vw;
  padding-left: 10vw;
  padding-right: 10vw;
  background-color: rgb(138, 154, 91, 0.8);
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.review-text {
  align-self: center;
  font-family: 'Georgia';
  font-size: 1.1vw;
  margin-bottom: 3vw;
}

.review-title {
  margin-bottom: 5vh;
  align-self: center;
  font-family: 'Georgia';
  font-size: 2.5vw;
  font-weight: bold;
}
.nav-list {
    display: flex;
    justify-content: space-between;
    width: 50vw;
}

.navbar-container {
    display: flex;
    justify-content: center;
    padding-top: 2vh;
}

.nav-button {
    background-color: #ffefd5;
    color: #8A9A5B;
    border: 2px solid #8A9A5B; /* Green */
    border-radius: 8px;
    transition-duration: 0.7s;
    font-size: 1.5vw;
}

.about-nav-button {
    background-color: #ffefd5;
    color: rgb(179,158,181);
    border: 2px solid rgb(179,158,181); /* Green */
    border-radius: 8px;
    transition-duration: 0.7s;
    font-size: 1.5vw;
}

.nav-button:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.about-nav-button:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.upcoming-nav-button {
    background-color: #ffefd5;
    color: rgb(181,114,129);
    border: 2px solid rgb(181,114,129); /* Green */
    border-radius: 8px;
    transition-duration: 0.7s;
    font-size: 1.5vw;
}

.upcoming-nav-button:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.home-nav-button {
    background-color: #ffefd5;
    color: rgb(0,128,128);
    border: 2px solid rgb(0,128,128); /* Green */
    border-radius: 8px;
    transition-duration: 0.7s;
    font-size: 1.5vw;
}

.home-nav-button:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.events-nav-button {
    background-color: #ffefd5;
    color: rgb(72, 99, 160);
    border: 2px solid rgb(72, 99, 160); /* Green */
    border-radius: 8px;
    transition-duration: 0.7s;
    font-size: 1.5vw;
}

.events-nav-button:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}
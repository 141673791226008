.homepage {
    text-align: center;
    background-color: rgba(0,128,128, 0.4);
    height: 210vh;
}

.home-header {
    font-size: 16vh;
    font-family: 'Oleo Script';
    margin-top: 5vh;
    margin-bottom: 2vh;
    color: #ffefd5;
    font-weight: normal;
}

.link-list {
    list-style: none;
}

.background-image {
    opacity: 0.4;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 210vh;
    z-index: -10;
}

.mission-container {
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 5vh;
    padding: 7vw;
    padding-left: 13vw;
    padding-right: 13vw;
    background-color: rgb(0,128,128, 0.8);
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.mission-text {
    align-self: center;
    font-family: 'Georgia';
    font-size: 1.5vw;
}

.mission-title {
    margin-bottom: 5vh;
    align-self: center;
    font-family: 'Georgia';
    font-size: 2.5vw;
    font-weight: bold;
}